import * as React from "react";

import Content from "../components/content";
import ContentTitle from "../components/content-title";
import FeaturesSection from "../components/features-section/features-section";
import Layout from "../components/layout";
import TabSection from "../components/tab-section";
import { SEO } from "../components/seo";

export const Head = () => <SEO />;

const Client = () => {
  return (
    <Layout headerSolid activeTab={2}>
      <ContentTitle />
      <Content />
      <FeaturesSection></FeaturesSection>
      <TabSection></TabSection>
    </Layout>
  );
};

export default Client;
